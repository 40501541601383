import Router from "vue-router"
import Vue from "vue"
Vue.use(Router)

import Home from "@/pages/en/home"
import Products from "@/pages/en/products"
import About from "@/pages/en/about"
import Contact from "@/pages/en/contact"
import Career from "@/pages/en/career"
import HomeZh from "@/pages/zh/home"
import ProductsZh from "@/pages/zh/products"
import AboutZh from "@/pages/zh/about"
import ContactZh from "@/pages/zh/contact"
import CareerZh from "@/pages/zh/career"

const routes = [
    {
        path: "/",
        component: Home,
        meta: { title: "Skyey Chemical" }
    },
    {
        path: "/products",
        component: Products,
        meta: { title: "Skyey Chemical - Products" }
    },
    {
        path: "/about",
        component: About,
        meta: { title: "Skyey Chemical - About" }
    },
    {
        path: "/contact",
        component: Contact,
        meta: { title: "Skyey Chemical - Contact" }
    },
    {
        path: "/career",
        component: Career,
        meta: { title: "Skyey Chemical - Career" }
    },
    {
        path: "/zh",
        component: HomeZh,
        meta: { title: "天津天域化工" }
    },
    {
        path: "/zh/products",
        component: ProductsZh,
        meta: { title: "天津天域化工 - 产品中心" }
    },
    {
        path: "/zh/about",
        component: AboutZh,
        meta: { title: "天津天域化工 - 关于我们" }
    },
    {
        path: "/zh/contact",
        component: ContactZh,
        meta: { title: "天津天域化工 - 联系我们" }
    },
    {
        path: "/zh/career",
        component: CareerZh,
        meta: { title: "天津天域化工 - 招聘" }
    }
]

const router = new Router({
    routes,
    mode: "history",
    publicPath: "/router"
})
router.beforeEach((to, from, next) => {
    document.querySelector("#app").scrollTop = 0
    document.title = to.meta.title
    let percent = 0.2
    if (["/contact", "/", "/zh/contact", "/zh"].includes(to.path)) percent = 0.16
    const bannerHeight = window.innerWidth * percent,
        scrollTop = document.getElementById("app").scrollTop
    document.getElementById("app").scrollTop = scrollTop > bannerHeight ? bannerHeight : 0
    next()
})

export default router
