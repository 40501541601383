<template>
  <div id="app" :class="($route.path==='/zh'?'home ':'')+($isMobile?' mobile':'')">
    <Header :options="headerOptions" v-if="!$isMobile"></Header>
    <mobile-header :options="headerOptions" v-else></mobile-header>
    <img class="leftImg" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/left.png">
    <img class="rightImg" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/right.png">
    <div class="page-container" :class="(drawer?'large-padding':'')">
      <router-view></router-view>
      <div class="subscribe" :class="$route.path=='/zh/career'?'no-margin':''">
        <p class="title jost">立即订阅！</p>
        <p><em class="desc jost zh">及时获取产品更新信息和活动信息</em></p>
        <div class="input-group flex">
          <input class="inp" type="text" placeholder="请输入您的邮箱" v-model="subscribe_email">
          <button class="btn" @click="subscribe"><i class="iconfont">&#xe712;</i></button>
        </div>
        <div class="bottom-contact-box flex justify-center" v-if="$isMobile">
          <div class="item flex justify-center align-center" @click="contact(1)"><i class="iconfont" style="margin-top:2px">&#xe6e5;</i></div>
          <div class="item flex justify-center align-center" @click="contact(2)"><i class="iconfont" style="font-size:19px">&#xe882;</i></div>
          <div class="item flex justify-center align-center" @click="contact(3)"><i class="iconfont" style="font-size:19px">&#xe62a;</i></div>
          <div class="item flex justify-center align-center" @click="contact(4)"><i class="iconfont" style="font-size:15px;margin-bottom:2px;">&#xee48;</i></div>
          <div class="item flex justify-center align-center" @click="contact(5)"><i class="iconfont" style="font-size:17px;margin-top:2px">&#xe610;</i></div>
        </div>
        <div class="bottom-info">Copyright © 2022 Skyey. All Rights Reserved. 天津天域 版权所有</div>
      </div>
    </div>
    <div class="right-contact-fixed-box" v-if="!$isMobile">
      <div class="item" @click="contact(1)"><img class="img" src="@/assets/images/facebook.jpg"></div>
      <div class="item" @click="contact(2)"><img class="img" src="@/assets/images/skype.jpg"></div>
      <div class="item" @click="contact(3)"><img class="img" src="@/assets/images/whatsapp.jpg"></div>
      <div class="item" @click="contact(4)"><img class="img" src="@/assets/images/linkedin.jpg"></div>
      <div class="item no-margin" @click="contact(5)"><img class="img" src="@/assets/images/wechat.jpg"></div>
    </div>
    <el-drawer title="是否允许使用本地存储？" direction="btt" :size="`${$isMobile?'290':'200'}px`" :visible.sync="drawer" :modal="false" :wrapperClosable="false">
      <p>为了给您提供更个性化的体验，此网站可能会会使用您计算机的本地存储能力，我们不会存储您的敏感信息，亦不会存储不合理的数据。</p>
      <p>使用本地存储例如cookie、localStorage等可以记住您在本网站的一些设置，比如网站语言等，在您下次打开网站的时候您将不需要再次选择语言。</p>
      <div class="btn-box">
        <el-button class="bk" size="mini" @click="accept">接受</el-button>
        <el-button size="mini" @click="decline">拒绝</el-button>
      </div>
    </el-drawer>
    <div class="drawer-sizesit" :style="drawer?`height:${$isMobile?'290':'200'}px`:''"></div>
  </div>
</template>

<script>
import util from '@/assets/js/util';
import Header from '@/components/Header';
import MobileHeader from '@/components/MobileHeader';

const headerOptions={
  publicPath: 'zh/',
  company: '天津天域进出口有限公司',
  motto: '可在此输入关于公司的简短描述或主题语',
  home: '首页',
  product: '产品',
  about: '关于我们',
  contact: '联系我们',
  career: '招聘',
}
export default {
  name: "App",
  components: { Header,MobileHeader },

  data() {
    return {
      headerOptions,
      subscribe_email: '',
      drawer: localStorage.getItem('lang')===null? true:false,
    }
  },

  methods: {
    //本地存储使用 - 接受
    accept() {
      localStorage.setItem('lang',this.$store.state.lang);
      this.drawer=false;
    },

    //本地存储使用 - 拒绝
    decline() {
      localStorage.setItem('lang',0);
      this.drawer=false;
    },

    //订阅
    subscribe: util.throttle(async function() {
      if(!this.subscribe_email) return this.$message.error('请输入您的邮箱');
      if(!this.subscribe_email.includes('@')) return this.$message.error('邮箱格式错误');
      this.$axios.post('subscribe',{ email: this.subscribe_email }).then(res => {
        const { data }=res;
        this.$message[data.code==1? 'success':'error'](data.msg.zh);
        if(data.code===1) this.subscribe_email='';
      })
    }),

    //联系方式点击
    contact(type) {
      if(type==1) window.location.href="https://www.facebook.com/bryson.wang.73";
      if(type==2) {
        if(navigator.clipboard&&window.isSecureContext) {
          navigator.clipboard.writeText('brysonwang').then(() => { this.$message.success('Skype用户名已复制至剪切板') },
            () => { this.$message.error('需允许网页使用剪切板以复制内容') })
        } else this.$message.error('浏览器版本过低，请在Skype中搜索用户：brysonwang')
      }
      if(type==3) window.location.href="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/whatsapp.jpg";
      if(type==4) window.location.href="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/linkedin.jpg";
      if(type==5) window.location.href="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/wechat.jpg";
    }
  }
}
</script>

<style lang="scss" src="@/assets/style/page_style/layout.scss"></style>