<template>
    <div>
        <div class="banner flex justify-center align-center">
            <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/contact.jpg" />
            <div class="title flex justify-center align-center">Contact Us</div>
        </div>
        <div class="module-container">
            <div class="top-box flex justify-between flex-start wrap">
                <div class="left animated bounceInLeft">
                    <div class="flex justify-between wrap">
                        <div class="form-group">
                            <p class="name">Name</p>
                            <input class="inp" placeholder="your name*" type="text" maxlength="20" v-model="form.name" />
                        </div>
                        <div class="form-group">
                            <p class="name">Email</p>
                            <input class="inp" placeholder="your email*" type="text" maxlength="50" v-model="form.email" />
                        </div>
                    </div>
                    <div class="flex justify-between wrap">
                        <div class="form-group">
                            <p class="name">Phone Number</p>
                            <input class="inp" placeholder="your phone number*" type="text" maxlength="20" v-model="form.phone" />
                        </div>
                        <div class="form-group">
                            <p class="name">Company</p>
                            <input class="inp" placeholder="your company name" type="text" maxlength="50" v-model="form.company" />
                        </div>
                    </div>
                    <div class="form-group full-width">
                        <p class="name">Intention</p>
                        <input class="inp" placeholder="your intention" type="text" maxlength="100" v-model="form.intention" />
                    </div>
                    <div class="form-group full-width" :class="{ flick: isEnquire }" v-show="isEnquire">
                        <p class="name">Request Documents</p>
                        <div class="chckbox-container">
                            <el-checkbox-group v-model="form.enquire">
                                <el-checkbox label="Certificate of Analysis"></el-checkbox>
                                <el-checkbox label="SDS"></el-checkbox>
                                <el-checkbox label="Specifications"></el-checkbox>
                                <el-checkbox label="Other"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div class="form-group full-width" v-show="hasOtherNeed">
                        <p class="name">Other Needs</p>
                        <input class="inp" placeholder="please specify" type="text" maxlength="100" v-model="otherNeed" />
                    </div>
                    <div class="form-group full-width little-margin">
                        <p class="name">Message</p>
                        <textarea class="inp area" type="text" maxlength="200" v-model="form.message"></textarea>
                    </div>
                    <div class="form-group">
                        <el-button class="sub-btn" @click="submit"><span style="color: #fff">Submit Request</span></el-button>
                    </div>
                </div>
                <div class="right animated bounceInRight">
                    <p class="title jost">Get In Touch</p>
                    <p class="desc muli">
                        You can get our contact information from below, or contact us through other apps on the right or bottom.
                    </p>
                    <div class="item muli ad">
                        <p class="line title jost">Reach Us</p>
                        <div class="tab-box">
                            <div class="tab-btn flex align-center">
                                <div class="btn" :class="addressActiveBtn == 1 ? 'active' : ''" @click="addressActiveBtn = 1">
                                    <el-tooltip class="item" effect="dark" ref="tooltip" :value="domOk" content="TIANJIN SKYEY CHEMICAL CO., LTD" placement="top-start">
                                        <el-button size="mini">TIANJIN CHINA</el-button>
                                    </el-tooltip>
                                </div>
                                <div class="btn" :class="addressActiveBtn == 2 ? 'active' : ''" @click="addressActiveBtn = 2">
                                    <el-tooltip class="item" effect="dark" content="SKYEY IMPEX LIMITED" placement="top-start">
                                        <el-button size="mini">HONGKONG</el-button>
                                    </el-tooltip>
                                </div>
                                <div class="btn" :class="addressActiveBtn == 3 ? 'active' : ''" @click="addressActiveBtn = 3">
                                    <el-tooltip class="item" effect="dark" content="S A INTERNATIONAL" placement="top-start">
                                        <el-button size="mini">INDIA</el-button>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="tab tab1" v-show="addressActiveBtn == 1">
                                <p class="line">
                                    ROOM 15N, 15/F, TEDA BUILDING, 256 JIEFANG SOUTH ROAD, HEXI DISTRICT, TIANJIN 300042 CHINA
                                </p>
                            </div>
                            <div class="tab tab2" v-show="addressActiveBtn == 2">
                                <p class="line">ROOM 1508, 15/F., OFFICE TOWER TWO, GRAND PLAZA, 625 NATHAN ROAD, KOWLOON, HONG KONG</p>
                            </div>
                            <div class="tab tab3" v-show="addressActiveBtn == 3">
                                <p class="line">
                                    BLOCK C-202, VASANTH NAGAR, PORANKI, KRISHNA DISTRICT, AP, INDIA
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="item muli em">
                        <p class="line title">Drop A Mail</p>
                        <p class="line">sales@skyeychem.com</p>
                    </div>
                    <div class="item muli ph">
                        <p class="line title">Call Us</p>
                        <p class="line">Tel: +86-22-82733288</p>
                        <p class="line">Mobile: +86-18722030668</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/assets/js/util"

const defaultForm = {
    name: "",
    email: "",
    phone: "",
    company: "",
    intention: "",
    message: "",
    enquire: []
}
export default {
    data() {
        return {
            form: JSON.parse(JSON.stringify(defaultForm)),
            addressActiveBtn: 1,
            otherNeed: "",
            domOk: false,
        }
    },

    methods: {
        submit: util.throttle(async function () {
            if (!this.form.name) return this.$message.error("Please enter your name")
            if (!this.form.email) return this.$message.error("Please enter your email")
            if (!this.form.phone) return this.$message.error("Please enter your phone number")
            if (!this.form.intention) return this.$message.error("Please enter your intention")
            if (!this.form.email.includes("@")) return this.$message.error("Bad email format")

            const requestData = Object.assign({}, this.form)
            const { enquire } = requestData
            if (this.hasOtherNeed) {
                if (!this.otherNeed) return this.$message.error("Please enter other needs")
                enquire[enquire.indexOf("Other")] = this.otherNeed
            }

            this.$axios.post("/api/intention", requestData).then(res => {
                const { data } = res
                this.$message.success(data.msg.en)
                this.form = JSON.parse(JSON.stringify(defaultForm))
            }).catch(err => {
                const { data } = err.response
                this.$message.error(data.msg.en)
            })
        })
    },

    computed: {
        isEnquire() {
            return this.form.intention === "enquire"
        },
        hasOtherNeed() {
            return this.form.enquire.includes("Other")
        }
    },

    mounted() {
        if (this.$route?.query.type === "enquire") {
            this.form.intention = "enquire"
            document.getElementById("app").scrollTop = 200;
        }
        setTimeout(() => {
            this.domOk = true
        }, 1000);
    }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/contact.scss"></style>
