<template>
  <div>
    <div class="banner flex justify-center align-center">
      <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/products.jpg" />
      <div class="title flex justify-center align-center">Products</div>
    </div>
    <div class="module-container flex justify-between">
      <div class="left animated slideInLeft faster" v-show="!$isMobile">
        <div class="item" :class="btnStatus['first'] ? 'active' : ''">
          <div class="btn-open" @click="btnStatus['first'] = !btnStatus['first']">Caustic Soda</div>
          <div class="list-box">
            <div class="list" :class="show == 1 ? 'active' : ''" @click="show = 1">General Information</div>
            <div class="list" :class="show == 2 ? 'active' : ''" @click="show = 2">Caustic Soda Flakes</div>
            <div class="list" :class="show == 3 ? 'active' : ''" @click="show = 3">Caustic Soda Pearls</div>
            <div class="list" :class="show == 4 ? 'active' : ''" @click="show = 4">Caustic Soda Solid</div>
            <div class="list" :class="show == 5 ? 'active' : ''" @click="show = 5">Caustic Soda Liquid</div>
          </div>
        </div>
        <div class="item" :class="btnStatus['second'] ? 'active' : ''">
          <div class="btn-open" @click="btnStatus['second'] = !btnStatus['second']">More Products</div>
          <div class="list-box large-height">
            <div class="list" :class="show == (index+6) ? 'active' : ''" v-for="(item,index) in otherProducts" :key="item" @click="show = index+6">{{item}}</div>
          </div>
        </div>
      </div>
      <div class="right animated slideInRight faster">
        <div class="outter" v-show="show == 1 || $isMobile">
          <div class="descriptions">
            <div class="title">Caustic Soda - General Information</div>
            <ul>
              <li class="desc-item">
                <p class="title">Professional</p>
                <p class="paragraph">We've been dealing with Caustic Soda (Sodium Hydroxide) since 2010.</p>
                <p class="paragraph">Global Sourcing keeps us more competitive in the market.</p>
              </li>
              <li class="desc-item">
                <p class="title">Basic Information</p>
                <table>
                  <tr class="colorful">
                    <td colspan="3">Caustic Soda (Sodium Hydroxide)</td>
                  </tr>
                  <tr>
                    <td>Molecular Formula</td>
                    <td colspan="2">NaOH</td>
                  </tr>
                  <tr>
                    <td>CAS NO.</td>
                    <td colspan="2">1310-73-2</td>
                  </tr>
                  <tr class="colorful">
                    <td>EINECS NO.</td>
                    <td colspan="2">215-185-5</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Caustic Soda, Solid</td>
                    <td>Caustic Soda, Liquid</td>
                  </tr>
                  <tr>
                    <td>IMO CLASS</td>
                    <td>8</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>UN NO.</td>
                    <td>1823</td>
                    <td>1824</td>
                  </tr>
                  <tr>
                    <td>H.S. CODE</td>
                    <td>2815.1100</td>
                    <td>2815.1200</td>
                  </tr>
                </table>
              </li>
              <li class="desc-item">
                <p class="title">Main Applications</p>
                <p class="paragraph">
                  Caustic Soda is a basic product very widely used in diverse industrial sectors, either as a raw
                  material or as an auxiliary chemical.
                </p>
                <p class="paragraph">
                  It is mainly used in the manufacture of alumina, textile processing, dyestuffs, pulp and paper,
                  viscose fibre, staple fiber, cotton, toilet and laundry soaps, detergents, lithium battery,
                  metallurgy, electroplating, drugs and pharmaceuticals, Food Additives and petroleum refining, etc.
                </p>
              </li>
              <li class="desc-item">
                <p class="title">Product Details</p>
                <p class="paragraph">
                  Caustic soda is produced in two forms - liquid (lye) and solids. Solids can be in the form of
                  flakes, pearls and solid.
                </p>
                <div class="img-box flex justify-between align-center">
                  <div class="img">
                    <img src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/products/detail1.png" />
                  </div>
                  <div class="img">
                    <img src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/products/detail2.png" />
                  </div>
                  <div class="img">
                    <img src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/products/detail3.png" />
                  </div>
                  <div class="img">
                    <img src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/products/detail4.png" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- 片碱 -->
        <div class="outter" v-show="show == 2 || $isMobile">
          <div class="descriptions">
            <div class="title">Caustic Soda Flakes</div>
            <ul>
              <li class="desc-item">
                <p class="title">Characteristics</p>
                <p class="paragraph">Products of different countries of origin are available.</p>
                <p class="paragraph">Products are devoid of any foreign matter, dirt, or other visible impurities.</p>
              </li>
              <li class="desc-item">
                <p class="title">Packaging</p>
                <p class="paragraph">Standard 25kg woven bags.</p>
                <p class="paragraph">Jumbo bags are available.</p>
                <p class="paragraph">Palletization are applicable.</p>
                <p class="paragraph">Customized package is applicable.</p>
              </li>
              <li class="desc-item">
                <p class="title">Food Additive</p>
                <p class="paragraph">Caustic Soda Flakes are available.</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 粒碱 -->
        <div class="outter" v-show="show == 3 || $isMobile">
          <div class="descriptions">
            <div class="title">Caustic Soda Pearls</div>
            <ul>
              <li class="desc-item">
                <p class="title">Characteristics</p>
                <p class="paragraph">Ultra High purity gives higher yield / productivity.</p>
                <p class="paragraph">Free flowing milky white granules.</p>
                <p class="paragraph">Dissolves readily and more easily in water.</p>
                <p class="paragraph">Products are devoid of any foreign matter, dirt, or other visible impurities.</p>
              </li>
              <li class="desc-item">
                <p class="title">Packaging</p>
                <p class="paragraph">Standard 25kg woven bags.</p>
                <p class="paragraph">Jumbo bags are available.</p>
                <p class="paragraph">Palletization are applicable.</p>
                <p class="paragraph">Customized package is applicable.</p>
              </li>
              <li class="desc-item">
                <p class="title">Food Additive</p>
                <p class="paragraph">Caustic Soda Pearls are available.</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 固碱 -->
        <div class="outter" v-show="show == 4 || $isMobile">
          <div class="descriptions">
            <div class="title">Caustic Soda Solid</div>
            <ul>
              <li class="desc-item">
                <p class="title">Characteristics</p>
                <p class="paragraph">Dissolves gradually in water.</p>
                <p class="paragraph">Products are devoid of any foreign matter, dirt, or other visible impurities.</p>
              </li>
              <li class="desc-item">
                <p class="title">Packaging</p>
                <p class="paragraph">200kg or 400kg iron drums.</p>
                <p class="paragraph">Palletization are applicable.</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- Potassium Hydroxide -->
        <div class="outter" v-show="show == 30 || $isMobile">
          <div class="descriptions">
            <div class="title">Potassium Hydroxide (Caustic Potash, KOH) 90%</div>
            <ul>
              <li class="desc-item">
                <p class="title">Characteristics</p>
                <p class="paragraph">CAS No.: 1310-58-3</p>
                <p class="paragraph">UN No.: 1813 Class 8</p>
                <p class="paragraph">White Flakes</p>
              </li>
              <li class="desc-item">
                <p class="title">Packaging</p>
                <p class="paragraph">Net 25kg/1000kg bag.</p>
              </li>
              <li class="desc-item">
                <p class="title">Food Additive</p>
                <p class="paragraph">It is an important chemical product and widely used in chemical, dye, light industry, food, pharmaceuticals, agriculture and other industries. </p>
              </li>
            </ul>
          </div>
        </div>

        <!-- SLES (Sodium Lauryl Ether Sulphate) -->
        <div class="outter" v-show="show == 33 || $isMobile">
          <div class="descriptions">
            <div class="title">SLES (Sodium Lauryl Ether Sulphate)</div>
            <ul>
              <li class="desc-item">
                <p class="title">Characteristics</p>
                <p class="paragraph">This is a high active, versatile, cosmetic-grade of Sodium Lauryl Ether Sulfate 70%.</p>
              </li>
              <li class="desc-item">
                <p class="title">Packaging</p>
                <p class="paragraph">Net 170kg plastic drum / IBC tank / Flexitank / ISO tank, etc.</p>
              </li>
              <li class="desc-item">
                <p class="title">Food Additive</p>
                <p class="paragraph">SLES is white to yellowish pourable paste, is easily soluble in water, and it is a kind of anionic surfactant with excellent performances. It performs good decontamination, emulsifying, wetting, densifying and foaming, with good solvency, wide compatibility, strong tolerance to hard water, high biodearadation. It is widely used in liquid detergent, such as dish washing, shampoo, bubble bath and hand washing, etc. SLES can also be used in textile, printing and dyeing oil and leather industries, it can be used in the lubricant, dyeing agent cleaner, foaming agent and degreasing agent.</p>
                <p class="paragraph">SLES is sensitive to changes in temperature and PH. Decomposition can occur above 40°C or in acidic conditions ( PH below 5 ). Please kindly store at 15-40°C.</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- Sodium Metasilicate -->
        <div class="outter" v-show="show == 40 || $isMobile">
          <div class="descriptions">
            <div class="title">Sodium Metasilicate (Pentahydrate / Anhydrate) </div>
            <ul>
              <li class="desc-item">
                <p class="title">Characteristics</p>
                <p class="paragraph">CAS No.: 10213-79-3</p>
                <p class="paragraph">UN No.: 3253</p>
              </li>
              <li class="desc-item">
                <p class="title">Packaging</p>
                <p class="paragraph">Net 25kg/50lb PP woven bags, 1000kg/2000lb super sack.</p>
              </li>
              <li class="desc-item">
                <p class="title">Food Additive</p>
                <p class="paragraph">It can replace STPP to be used for the manufacture of effective detergents and rust remover for metal, reducing environmental pollution, preventing corrosion for metal (zinc, aluminum, etc.). Therefore, it is widely used for the manufacture of a variety of detergents, used as rust remover for metal, grinding aids for ceramics, deinking agent for paper, dyeing and printing auxiliary for removing oil in textile industry, and also used as the raw material for flameproofing agent, plastic maintenance agent, water retention agent, etc.</p>
                <p class="paragraph">Used in water reducing agents, sodium it is able to integrate more hydrones to make better moisture absorption effect.</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 液碱 -->
        <div class="outter" v-show="show == 5 || $isMobile">
          <div class="descriptions">
            <div class="title">Caustic Soda Liquid</div>
            <ul>
              <li class="desc-item">
                <p class="title">Characteristics</p>
                <p class="paragraph">Advanced ion-exchange membrane technology.</p>
                <p class="paragraph">Chemical process: Electrolysis method.</p>
                <p class="paragraph">Products are devoid of any foreign matter, dirt, or other visible impurities.</p>
              </li>
              <li class="desc-item">
                <p class="title">Packaging</p>
                <p class="paragraph">IBC Tank or 200L HDPE drums.</p>
                <p class="paragraph">Bulk vessel shipment.</p>
              </li>
              <li class="desc-item">
                <p class="title">Food Additive</p>
                <p class="paragraph">Caustic Soda Liquid are available. </p>
              </li>
            </ul>
          </div>
        </div>

        <div class="desc-item">
          <p class="title">Product Details</p>
          <p class="paragraph">
            Our team of experts are at the ready. Fill out the form below and we will be in touch shortly.
          </p>
          <router-link to="contact?type=enquire">
            <button class="enquire-btn">Enquire Now &gt;&gt;</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnStatus: { first: true, second: false },
      show: 1,
      otherProducts: [
        "Acetic Acid Glacial",
        "ACH (Aluminum Chlorohydrate)",
        "Aluminium Sulphate",
        "Ammonium Chloride",
        "Ammonium Sulphate",
        "Aniline",
        "Barium Sulphate",
        "Benzene",
        "Benzoic Acid",
        "Calcium Chloride",
        "Calcium Hypochlorite",
        "Citric Acid",
        "DAP (Diammonium Phosphate)",
        "Formic Acid",
        "Hydrazine Hydrate",
        "Hydrofluoric Acid",
        "Hydrogen Peroxide",
        "Industrial Salt",
        "Isocyanuric Acid",
        "MSG (Monosodium Glutamate)",
        "Oxalic Acid",
        "PAC (Poly Aluminium Chloride)",
        "PFS (Poly Ferric Sulfate)",
        "Phosphoric Acid",
        "Potassium Hydroxide",
        "PVC Resin",
        "SDIC (Sodium Dichloroisocyanurate)",
        "SLES (Sodium Lauryl Ether Sulphate)",
        "Soda Ash",
        "Sodium Acetate Trihydrate",
        "Sodium Bicarbonate",
        "Sodium Citrate",
        "Sodium Hydrosulphide",
        "Sodium Hydrosulphite",
        "Sodium Metasilicate",
        "Sodium Nitrate",
        "Sodium nitrite",
        "Sodium Silicate",
        "Sodium Sulphate Anhydrous",
        "Sodium Sulphide",
        "Sodium Sulphite",
        "Sodium Thiosulfate",
        "Stearic Acid",
        "TCCA (Trichloroisocyanuric Acid)",
        "Titanium Dioxide",
        "Zeolite 4A",
      ]
    }
  },
  created() {
    const type = parseInt(this.$route.query.type)
    if (!type) {
      return
    }
    if ([1, 2, 3, 6, 7].includes(type)) {
      this.show = 2
    } else if ([4, 5].includes(type)) {
      this.show = 3
    } else if ([7, 8, 9].includes(type)) {
      if (type === 8) {
        this.show = 31
      } else if (type === 9) {
        this.show = 28
      } else if (type === 9) {
        this.show = 38
      }
      this.btnStatus = { first: false, second: true }
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/products.scss"></style>
