<template>
  <div>
    <div class="header-fixed" :class="{ marginTopZero }" :style="`margin-top:${marginTop}px`">
      <div class="header flex">
        <div class="left flex align-center">
          <router-link to="/">
            <img class="img" src="@/assets/images/logo2.png" />
          </router-link>
          <!-- <div class="motto-box">
            <p class="top">{{options.company}}</p>
            <p class="bottom ellipsis">{{options.motto}}</p>
          </div> -->
        </div>
        <div class="right flex align-center justify-between">
          <el-menu
            :default-active="$route.path"
            class="menu-box flex align-center justify-between"
            mode="horizontal"
            text-color="#686666"
            active-text-color="#2B89FD"
            :router="true"
          >
            <el-menu-item :index="`/${options.publicPath.replace('/', '')}`">{{ options.home }}</el-menu-item>
            <el-menu-item :index="`/${options.publicPath}products`">{{ options.product }}</el-menu-item>
            <el-menu-item :index="`/${options.publicPath}about`">{{ options.about }}</el-menu-item>
            <el-menu-item :index="`/${options.publicPath}contact`">{{ options.contact }}</el-menu-item>
            <el-menu-item :index="`/${options.publicPath}career`">{{ options.career }}</el-menu-item>
          </el-menu>
          <el-select size="small" :value="$lang" @change="langChange" popper-class="use-hover">
            <el-option v-for="item in languages" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="size-sit"></div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: { options: null, marginTop: Number },

  data() {
    return {
      marginTopZero: false,
      languages: [
        { val: "en", label: "English" },
        // { val: "zh", label: "简体中文" }
      ]
    }
  },

  watch: {
    marginTop(newValue) {
      this.marginTopZero = newValue === -60
    }
  },

  methods: {
    langChange(lang) {
      const oriLang = localStorage.getItem("lang"),
        url = window.location.href.replace(this.$langReg, "")
      if (oriLang && oriLang !== "0") localStorage.setItem("lang", lang)
      if (lang == "en") window.location.href = url
      else window.location.href = url.replace(/(http.?:\/\/.*?\/)(.*)/, `$1${lang}/$2`)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  margin: 0 auto;
  z-index: 999;
  background-color: #fff;

  &.marginTopZero {
    margin-top: 0 !important;
    transition: margin 400ms;
    border-bottom: 1px solid #f5f5f5;
  }

  .left {
    width: 237.28px;

    .img {
      width: 100%;
      height: 70px;
    }

    /* .motto-box {
      width: calc(100% - 237.28px);
      padding-left: 15px;
      .top {
        font-size: 20px;
        color: $main-color;
        font-weight: bold;
        letter-spacing: 0.8px;
      }
      .bottom {
        font-size: 14px;
        color: #9e9e9e;
        margin-top: 3px;
      }
    } */
  }

  .right {
    width: calc(100% - 250px);

    .menu-box {
      width: calc(100% - 100px);
      padding: 0 60px;
    }
  }
}

.size-sit {
  width: 100%;
  height: 90px;
}

:deep(.el-menu.el-menu--horizontal),
:deep(.el-menu--horizontal > .el-d-item.is-active),
:deep(.el-menu--horizontal > .el-menu-item.is-active),
:deep .el-menu--horizontal > .el-menu-item {
  border-bottom: none !important;
}

:deep(.el-menu--horizontal > .el-d-item.is-active),
:deep(.el-menu--horizontal > .el-menu-item.is-active) {
  color: $main-color !important;
}

:deep(.el-menu--horizontal > .el-menu-item) {
  font-size: 16px;
  color: #777 !important;
  font-weight: bold;
  letter-spacing: 0.8px;
}

:deep(.long .el-menu--horizontal > .el-menu-item) {
  font-size: 15px;
}

.el-select {
  width: 100px;
  margin-top: 5px;
}

:deep(.el-select .el-input__inner):focus {
  border-color: $main-color;
}
</style>
