<template>
  <div>
    <div class="navigation-bar flex justify-between align-center">
      <div class="left flex">
        <div class="img-box" @click="navigate('/')">
          <img class="img" src="@/assets/images/logo.png">
        </div>
        <div class="motto">
          <div class="m-top">{{options.company}}</div>
          <div class="m-bottom ellipsis">{{options.motto}}</div>
        </div>
      </div>
      <div class="icon-box flex">
        <i class="icon iconfont" @click="navModalShow=true">&#xe6101;</i>
      </div>
    </div>
    <div class="sizesit"></div>

    <div class="modal flex justify-end" :class="navModalShow?'show':''" @click="navModalShow=false">
      <div class="content" @click.stop>
        <div class="nav-list">
          <el-select size="small" :value="$lang" @change="langChange">
            <el-option v-for="item in languages" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </div>
        <div class="icon-box" @click="navModalShow=false">
          <i class="icon close iconfont">&#xe6001;</i>
        </div>
        <div class="nav-list" @click="navigate(`/${options.publicPath.replace('/','')}`)">
          <div class="title">{{options.home}}</div>
        </div>
        <div class="nav-list" @click="navigate(`/${options.publicPath}products`)">
          <div class="title">{{options.product}}</div>
        </div>
        <div class="nav-list" @click="navigate(`/${options.publicPath}about`)">
          <div class="title">{{options.about}}</div>
        </div>
        <div class="nav-list" @click="navigate(`/${options.publicPath}contact`)">
          <div class="title">{{options.contact}}</div>
        </div>
        <div class="nav-list no-border" @click="navigate(`/${options.publicPath}career`)">
          <div class="title">{{options.career}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { options: null },

  data() {
    return {
      navModalShow: false,
      languages: [
        { val: 'en', label: 'English' },
        // { val: 'zh', label: '简体中文' }
      ]
    }
  },

  methods: {
    //语言切换
    langChange(lang) {
      const oriLang = localStorage.getItem('lang'),
        url = window.location.href.replace(this.$langReg, '');
      if (oriLang && oriLang !== '0') localStorage.setItem('lang', lang);
      if (lang == 'en') window.location.href = url;
      else window.location.href = url.replace(/(http.?:\/\/.*?\/)(.*)/, `$1${lang}/$2`);
    },

    //页面切换
    navigate(path) {
      this.navModalShow = false;
      if (path == this.$route.path) return;
      this.$router.push(path);
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 77px;
  padding: 18px 15px 14px;
  overflow: hidden;
  background-color: #fff;
  z-index: 998;
}
.sizesit {
  width: 100%;
  height: 77px;
}
.navigation-bar .left {
  width: calc(100% - 29px);
}
.navigation-bar .left .img {
  width: 40px;
  height: 40px;
}
.navigation-bar .left .motto {
  width: calc(100% - 40px);
  letter-spacing: 0.3px;
  margin-left: 8px;
}
.navigation-bar .left .m-top {
  font-size: 14px;
  color: $main-color;
  font-weight: bold;
}
.navigation-bar .left .m-bottom {
  width: calc(100% - 10px);
  font-size: 11px;
  color: #9e9e9e;
  margin-top: 4px;
}
.navigation-bar .icon-box {
  padding: 0 3px 5px;
}
.navigation-bar .icon-box .icon {
  font-size: 22px;
  color: #3d3d3d;
  font-weight: bold;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  z-index: 999;
  transition: all ease-in-out 0.3s;
  pointer-events: none;
  background: #0000004f;
}
.modal .content {
  position: absolute;
  top: 77px;
  width: 300px;
  height: calc(100vh - 137px - constant(safe-area-inset-bottom));
  height: calc(100vh - 137px - env(safe-area-inset-bottom));
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  transform: translate(100%, 0);
  transition: all ease-in-out 0.3s;
  overflow: hidden;
  overflow-y: visible;
  background: #fff;
}
.modal.show {
  opacity: 1;
  pointer-events: auto;
}
.modal.show .content {
  transform: translate(0, 0);
  padding: 30px 50px;
  text-align: center;
}
.modal .content .icon-box {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}
.modal .content .icon.close {
  font-size: 20px;
  color: #ccc;
}

.nav-list {
  padding-bottom: 30px;
  margin-top: 30px;
  border-bottom: 1px solid #f5f5f5;
}
.nav-list.no-border {
  border: none;
}
.nav-list .title {
  position: relative;
  font-size: 14px;
  font-weight: 440;
  letter-spacing: 0.5px;
}
.el-select {
  width: 100px;
  margin-top: 5px;
}
:deep(.el-select .el-input__inner):focus {
  border-color: $main-color;
}

@media screen and (min-width: 500px) {
  .navigation-bar {
    padding: 18px 20 14px;
  }
}
</style>