<template>
  <div>
    <div class="banner flex justify-center align-center">
      <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/career-banner.png" />
      <div class="title flex justify-center align-center">Career</div>
    </div>

    <div class="module-container flex justify-between">
      <div class="left" v-if="!$isMobile">
        <div class="list-box">
          <div class="title">Type</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 0)">Fresh Graduate</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, `0`)">Intern</el-checkbox>
          </div>
        </div>
        <div class="list-box">
          <div class="title">Project</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 2)">Autumn Recruitment</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 3)">Social Recruitment</el-checkbox>
          </div>
        </div>
        <div class="list-box">
          <div class="title">Category</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 4)">Sales</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 5)">Purchase</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 6)">Logistics</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 7)">Market</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 8)">Operational</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 9)">Function/Support</el-checkbox>
          </div>
        </div>
        <div class="list-box">
          <div class="title">City</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 10)">TianJin</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 11)">HongKong</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 12)">New Delhi / Mumbai</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 13)">Los Angeles</el-checkbox>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="no-content" v-show="no">
          <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/no-content.png" />
          <p class="tip">There are no job openings in this category, and we look forward to your attention...</p>
        </div>
        <div class="item bold" v-show="m1">
          <div class="title">Our Culture</div>
          <p class="paragraph large">
            - We credit our success and rapid growth to our greatest asset: <b>Our People</b>. We also believe in imparting a sense of belonging to our employees and take immense pride in having them as our backbone.
          </p>
          <p class="paragraph large">
            - Our culture is defined by these values of Integrity, Agility, Customer Centricity, Teamwork, Openness and Newness.
          </p>
          <p class="paragraph large">
            - Honesty is the best policy; the policy we choose to base our business on. We aspire to make integrity the cornerstone of all our dealings.
          </p>
          <p class="paragraph large">
            - Commitment demands delivering as promised. Creating a culture high on reliability and accountability.
          </p>
        </div>
        <div class="item bold" v-show="m1">
          <div class="title">Join SKYEY</div>
          <p class="paragraph large">
            <span class="tip">
              "Coming together is a beginning. Keeping together is progress. Working together is success." - Henry Ford
            </span>
          </p>
          <p class="paragraph large">
            - SKYEY is committed to hire, develop, and retain talented people.
          </p>
          <p class="paragraph large">
            - We don’t just offer jobs, rather we provide a career. A career that gives you the opportunity to explore new horizon of your capabilities. If you are willing to make a career with a growth oriented organization, where you will be able to use your analytical skills and aptitude to excel, then SKYEY is the right place for you.
          </p>
          <p class="paragraph large">
            - We seek individuals who are commercially minded and with a can-do attitude, who strive for growth and continual improvement in order to make an impact.
          </p>
          <p class="paragraph large">
            - Being a value driven organization, with human touch at the core of its guiding philosophy, we value our talent, understand their lives, and support and motivate them for high performance and results.
          </p>
          <p class="paragraph large">
            - By delivering results, you are able to gain progression, recognition and rewards.
          </p>
        </div>
        <div class="item" v-show="m2">
          <div class="title">International Sales (Export)</div>
          <div class="desc flex align-center">
            <p class="bar">Tianjin / New Delhi / Mumbai</p>
            <p class="bar">Sales</p>
            <p class="bar">Social Recruitment</p>
            <p>Formal / Part time</p>
          </div>
          <p class="paragraph">
            1. Present, promote and sell products/services using solid arguments to existing and prospective customers. Reach out to customer leads through emails, social media tools, phone calls, etc. Establish, develop and maintain positive business and customer relationships.
          </p>
          <p class="paragraph">
            2. Achieve agreed upon sales targets and outcomes within schedule. Expedite the resolution of customer problems and complaints to maximize satisfaction.
          </p>
          <p class="paragraph">
            3. Coordinate sales effort with team members and other departments. Analyze the territory/market's potential, track sales and status reports. Supply management with reports on customer needs, problems, interests, competitive activities, and potential for new products and services.
          </p>
        </div>
        <div class="item" v-show="m3">
          <div class="title">Procurement</div>
          <div class="desc flex align-center">
            <p class="bar">Tianjin</p>
            <p class="bar">Purchase</p>
            <p class="bar">Social Recruitment</p>
            <p>Formal</p>
          </div>
          <p class="paragraph">
            1. Build vendor relationships to secure the best prices on products/services.
          </p>
          <p class="paragraph">
            2. Purchase goods or services that meet the quantity and quality expectations of our customers. Evaluate and negotiate contracts with vendors.
          </p>
          <p class="paragraph">
            3. Track inventory and restock goods when needed. Stay up to date on industry trends and new products. Compare available goods with industry trends to determine appropriate pricing.
          </p>
          <p class="paragraph">
            4. Keep monitoring the market and sourcing new potential suppliers to keep our competitiveness.
          </p>
        </div>
        <div class="item" v-show="m4">
          <div class="title">Global Selling- Business Analysis</div>
          <div class="desc flex align-center">
            <p class="bar">TianJin</p>
            <p class="bar">Market</p>
            <p class="bar">Social Recuritment</p>
            <p>Formal</p>
          </div>
          <p class="paragraph">
            1. Build and improve the business analysis framework around the flywheel of business growth, formulate and
            iterate the business analysis index system, deeply analyze the core driving factors of the business, clearly
            locate the business problems, and provide powerful strategic suggestions for the improvement of business
            operations;
          </p>
          <p class="paragraph">
            2. Establish the target and indicator management system from the top down, participate in the annual/monthly
            planning objectives and strategy formulation, and monitor and evaluate the effect of business implementation
            strategies to promote the achievement of business objectives;
          </p>
          <p class="paragraph">
            3. Continue to study the business problems arising in the operation process, promote thematic analysis, and
            promote the implementation of more complex projects in the business;
          </p>
          <p class="paragraph">
            4. Formulate the goals and strategies for future business model exploration, and coordinate with the
            business to implement, monitor and review the strategies to ensure the expected results of the strategies.
          </p>
        </div>
        <div class="item" v-show="m5">
          <div class="title">Marketing Manager</div>
          <div class="desc flex align-center">
            <p class="bar">TianJin</p>
            <p class="bar">Market</p>
            <p class="bar">Social Recuritment</p>
            <p>Formal</p>
          </div>
          <p class="paragraph">
            1. to complete the development and maintenance of regional customers: consulting sales, to provide customers
            with high-quality services and valuable suggestions;
          </p>
          <p class="paragraph">
            2. Completion of sales targets: responsible for negotiating and signing contracts with local intermediary
            customers, achieving established performance income, completing KPI delivered by the company, analyzing
            business data in the region, monitoring business operations, and exploring possible performance growth
            points;
          </p>
          <p class="paragraph">
            3. old customer relationship maintenance at all levels, committed to professional services, establish the
            company's professional brand image;
          </p>
          <p class="paragraph">
            4. according to customer feedback to provide information to help decision-making, improve products and
            continue to provide service value beyond customer expectations.
          </p>
        </div>
        <div class="item" v-show="m6">
          <div class="title">HRBP - Human Resource Partner</div>
          <div class="desc flex align-center">
            <p class="bar">TianJin</p>
            <p class="bar">Function/Support - Manpower</p>
            <p class="bar">Social Recuritment</p>
            <p>Formal</p>
          </div>
          <p class="paragraph">1. Responsible for the overall recruitment progress of the business unit;</p>
          <p class="paragraph">
            2. Understand the business status and team operation of the supported department, understand the business trategy and promote the effective implementation of HR strategy in the business department;
          </p>
          <p class="paragraph">
            3. Provide support for the development of the team, apply professional theories, methods, tools, and provide all-round solutions for the business team. Including recruitment, training, performance, employee relationsand other human resources work;
          </p>
          <p class="paragraph">4. Promote the construction of company culture and the rapid development of business.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bs: Array(14).fill(false)
    }
  },

  methods: {
    fn(b, i) {
      this.$set(this.bs, i, b)
    },
    check(requires) {
      let ok = !this.bs.slice(0, 2).some(e => e)
      if (!ok) {
        return false
      }
      for (let i = 0; i < 3; i++) {
        if (i === 0) {
          ok = !this.bs.slice(2, 4).some(e => e)
          ok ||= this.bs[requires[0] + 2]
        } else if (i === 1) {
          ok = !this.bs.slice(4, 10).some(e => e)
          ok ||= this.bs[requires[1] + 4]
        } else {
          ok = !this.bs.slice(10).some(e => e)
          ok ||= requires[2].some(e => {
            return this.bs[e + 10]
          })
        }
        if (!ok) {
          return false
        }
      }
      return ok
    }
  },

  computed: {
    no() {
      for (let i = 1; i < 7; i++) {
        if (this["m" + i]) {
          return false
        }
      }
      return true
    },
    m1() {
      return !this.bs.some(e => e)
    },
    m2() {
      return this.check([1, 0, [0, 2]])
    },
    m3() {
      return this.check([1, 1, [0]])
    },
    m4() {
      return this.check([1, 3, [0]])
    },
    m5() {
      return this.check([1, 3, [0]])
    },
    m6() {
      return this.check([1, 5, [0]])
    },
  }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/career.scss"></style>
