<template>
  <div>
    <div class="banner flex justify-center align-center">
      <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/products.jpg">
      <div class="title flex justify-center align-center">产品中心</div>
    </div>
    <div class="module-container flex justify-between">
      <div class="left animated slideInLeft faster">
        <div class="item" :class="btnStatus['first']?'active':''">
          <div class="btn-open" @click="btnStatus['first']=!btnStatus['first']">主营产品</div>
          <div class="list-box">
            <div class="list" :class="show==1?'active':''" @click="show=1">片碱</div>
            <div class="list" :class="show==2?'active':''" @click="show=2">粒碱</div>
            <div class="list" :class="show==3?'active':''" @click="show=3">固碱</div>
            <div class="list" :class="show==4?'active':''" @click="show=4">液碱</div>
          </div>
        </div>
        <div class="item" :class="btnStatus['second']?'active':''">
          <div class="btn-open" @click="btnStatus['second']=!btnStatus['second']">其他产品</div>
          <div class="list-box large-height">
            <div class="list" :class="show==5?'active':''" @click="show=5">PVC</div>
            <div class="list" :class="show==6?'active':''" @click="show=6">SLES</div>
            <div class="list" :class="show==7?'active':''" @click="show=7">LABSA</div>
            <div class="list" :class="show==8?'active':''" @click="show=8">KOH</div>
            <div class="list" :class="show==9?'active':''" @click="show=9">硫化钠</div>
            <div class="list" :class="show==10?'active':''" @click="show=10">硫氢化钠</div>
            <div class="list" :class="show==11?'active':''" @click="show=11">保险粉</div>
            <div class="list" :class="show==12?'active':''" @click="show=12">冰醋酸</div>
            <div class="list" :class="show==13?'active':''" @click="show=13">柠檬酸</div>
            <div class="list" :class="show==14?'active':''" @click="show=14">纯碱</div>
            <div class="list" :class="show==15?'active':''" @click="show=15">元明粉</div>
            <div class="list" :class="show==16?'active':''" @click="show=16">大苏打</div>
            <div class="list" :class="show==17?'active':''" @click="show=17">小苏打</div>
            <div class="list" :class="show==18?'active':''" @click="show=18">双氧水</div>
            <div class="list" :class="show==19?'active':''" @click="show=19">磷酸</div>
            <div class="list" :class="show==20?'active':''" @click="show=20">次氯酸钙</div>
            <div class="list" :class="show==21?'active':''" @click="show=21">氯化铵</div>
            <div class="list" :class="show==22?'active':''" @click="show=22">沸石</div>
          </div>
        </div>
      </div>
      <div class="right animated slideInRight faster">
        <!-- 片碱 -->
        <div v-show="show==1">
          <div class="desc-box">
            <p class="title"><strong>Caustic Soda Flakes 99% [片碱]</strong></p>
            <p class="label"><strong>产品外观: </strong> white flakes.</p>
            <p class="label"><strong>产品包装: </strong></p>
            <p class="dot">25kg bags, Max. 27MT/20'FCL.</p>
            <p class="dot"> If palletized 25kg bags with shrink wrapped, Max. 24MT/20'FCL.</p>
            <p class="dot">Net 1000kg/1100kg bags, 20 jumbo bags/20'FCL.</p>
          </div>
          <div class="table flex justify-between wrap">
            <div class="tr bb">
              <div class="td middle-width title start">Composing</div>
              <div class="td">
                <div class="tr">
                  <div class="td pad title bb bl">Caustic Soda Flakes 99%</div>
                </div>
                <div class="tr">
                  <div class="td pad title br bl">Superior Class</div>
                  <div class="td pad title br">First Class</div>
                  <div class="td pad title">Qualified</div>
                </div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaOH (Sodium Hydroxide)</div>
              <div class="tr">
                <div class="td pad bl br">99% Min.</div>
                <div class="td pad br">98.5% Min.</div>
                <div class="td pad">98% Min.</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">Na2CO3 (Sodium Carbonate)</div>
              <div class="tr">
                <div class="td pad bl br">0.5% Max.</div>
                <div class="td pad br">0.8% Max.</div>
                <div class="td pad">1% Max.</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaCl (Sodium Chloride)</div>
              <div class="tr">
                <div class="td pad bl br">NaCl (Sodium Chloride)</div>
                <div class="td pad br">0.05% Max.</div>
                <div class="td pad">0.05% Max.</div>
              </div>
            </div>
            <div class="tr">
              <div class="td middle-width small-title start">Fe2O3(Ferric Oxide)</div>
              <div class="tr">
                <div class="td pad bl br">0.005% Max.</div>
                <div class="td pad br">0.008% Max.</div>
                <div class="td pad">0.01% Max.</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 粒碱 -->
        <div v-show="show==2">
          <div class="desc-box">
            <p class="title"><strong>Caustic Soda Pearls 99% [粒碱]</strong></p>
            <p class="label"><strong>产品外观: </strong>Pure white micro-pearls, Size of pearl: 0.6-0.9mm (80%).</p>
            <p class="label"><strong>产品包装: </strong></p>
            <p class="dot">Net 25kg bags, Max 28MT/20’FCL.</p>
            <p class="dot"> If palletized 25kg bags with shrink wrapped, Max 25MT/20’FCL.</p>
            <p class="dot">If palletized 25kg steel drums, Max 18MT/20’FCL.</p>
            <p class="dot">Net 1000kg/1200kg bags, 20 jumbo bags/20'FCL.</p>
          </div>
          <div class="table flex justify-between wrap">
            <div class="tr bb">
              <div class="td middle-width title start">Inspection Items</div>
              <div class="td">
                <div class="tr">
                  <div class="td pad title bb bl">Quality Index</div>
                </div>
                <div class="tr">
                  <div class="td pad title br bl">Excellent</div>
                  <div class="td pad title">First-grade</div>
                </div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">Appearance</div>
              <div class="tr">
                <div class="td pad bl">White Pearls</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaOH %</div>
              <div class="tr">
                <div class="td pad bl br">≥ 99.0</div>
                <div class="td pad">≥ 99.0</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">Na2CO3 %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.4</div>
                <div class="td pad">≤ 0.5</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaCl %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.02</div>
                <div class="td pad">≤ 0.02</div>
              </div>
            </div>
            <div class="tr">
              <div class="td middle-width small-title start">Fe2O3 %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.004</div>
                <div class="td pad">≤ 0.005</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 固碱 -->
        <div v-show="show==3">
          <div class="desc-box">
            <p class="title"><strong>Caustic Soda Solid 99% [固碱]</strong></p>
            <p class="label"><strong>产品外观: </strong>White solid</p>
            <p class="label"><strong>产品包装: </strong></p>
            <p class="dot">Net 200kg iron drums, Max 28MT/ 20’FCL.</p>
            <p class="dot">Net 400kg iron drums, Max 26.8MT/ 20’FCL.</p>
          </div>
          <div class="table flex justify-between wrap">
            <div class="tr bb">
              <div class="td middle-width title start">Inspection Items</div>
              <div class="td">
                <div class="tr">
                  <div class="td pad title bb bl">Quality Index</div>
                </div>
                <div class="tr">
                  <div class="td pad title br bl">Excellent</div>
                  <div class="td pad title">First-grade</div>
                </div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">Appearance</div>
              <div class="tr">
                <div class="td pad bl">White Pearls</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaOH %</div>
              <div class="tr">
                <div class="td pad bl br">≥ 99.0</div>
                <div class="td pad">≥ 99.0</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">Na2CO3 %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.4</div>
                <div class="td pad">≤ 0.5</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaCl %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.02</div>
                <div class="td pad">≤ 0.03</div>
              </div>
            </div>
            <div class="tr">
              <div class="td middle-width small-title start">Fe2O3 %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.004</div>
                <div class="td pad">≤ 0.005</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 液碱 -->
        <div v-show="show==4">
          <div class="desc-box">
            <p class="title"><strong>Caustic Soda Liquid (Lye) [液碱]</strong></p>
            <p class="label"><strong>Characteristics: </strong>Ionic membrane (IEM) and transparent liquid.</p>
            <p class="label"><strong>产品包装: </strong></p>
            <p class="dot">IBC Tank, 200L HDPE drum, Bulk Vessel are available.</p>
            <p class="dot">If IBC Tank, max 16 Tanks (24MT) each 20’FCL.</p>
            <p class="dot">If 200L HDPE drum, max 80 drums (24MT) each 20’FCL.</p>
          </div>
          <div class="table flex justify-between wrap">
            <div class="tr bb">
              <div class="td middle-width title start">Inspection Items</div>
              <div class="td">
                <div class="tr">
                  <div class="td pad title bb bl">Quality Index</div>
                </div>
                <div class="tr">
                  <div class="td pad title br bl">Excellent</div>
                  <div class="td pad title">First-grade</div>
                </div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">Appearance</div>
              <div class="tr">
                <div class="td pad bl">White Pearls</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaOH %</div>
              <div class="tr">
                <div class="td pad bl br">≥ 99.0</div>
                <div class="td pad">≥ 99.0</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">Na2CO3 %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.4</div>
                <div class="td pad">≤ 0.5</div>
              </div>
            </div>
            <div class="tr bb">
              <div class="td middle-width small-title start">NaCl %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.02</div>
                <div class="td pad">≤ 0.03</div>
              </div>
            </div>
            <div class="tr">
              <div class="td middle-width small-title start">Fe2O3 %</div>
              <div class="tr">
                <div class="td pad bl br">≤ 0.004</div>
                <div class="td pad">≤ 0.005</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnStatus: { first: true,second: true },
      show: 1
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/products.scss"></style>