import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper"
import "swiper/css/swiper.css"
import $ from "jquery"

export default {
  name: "App",
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive
  },

  data() {
    return {
      drawer: localStorage.getItem("lang") === null ? true : false,
      swiperOptions: {
        effect: "fade",
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        paginationClickable: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    }
  },

  methods: {
    navigateToProducts(n) {
      this.$router.push("/products?type=" + n)
    }
  },

  mounted() {
    $(".home-module").removeClass("show animated faster slideInLeft slideInRight")
    const moduleHeights = [],
      halfWindowHeight = window.innerHeight / 1.2
    $(".home-module").each((_, elem) => {
      moduleHeights.push($(elem).offset().top)
    })
    $("#app").scroll(() => {
      for (let i = 0; i < moduleHeights.length; i++) {
        if ($("#app").scrollTop() >= moduleHeights[i] - halfWindowHeight) {
          $($(".home-module")[i]).addClass(`show animated faster slideIn${i % 2 === 0 ? "Left" : "Right"}`)
        }
      }
    })
  },

  destroyed() {
    $("#app").unbind("scroll")
  }
}
