export function throttle(fn, wait = 1000) {
    let last = 0
    return function () {
        const now = new Date().getTime()
        if (now - last > wait) {
            fn.apply(this, arguments)
            last = now
        }
    }
}

export default { throttle }
