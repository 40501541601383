import Vue from "vue";
import "./assets/style/animate.css";
import "./assets/style/main.scss";
import "./assets/style/font.scss";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "@/assets/js/store";
import router from "@/assets/js/router";
import axios from "axios";
import en from "./pages/en/layout";
import zh from "./pages/zh/layout";

Vue.prototype.$isMobile = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(navigator.userAgent);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);

// 判断当前所选择的语言
const App = { en, zh },
    languages = ["en", "zh"],
    url = window.location.href.replace(/http.?:\/\/.*?\//, "");

let lang = "",
    reg = "";
for (let i = 0; i < languages.length; i++) {
    if (url.includes(languages[i])) lang = languages[i];
    reg += `(${languages[i]}\\/?)|`;
}
lang = lang || "en";
reg = reg.slice(0, reg.length - 1);
Vue.prototype.$lang = lang;
Vue.prototype.$langReg = new RegExp(reg);

new Vue({
    store,
    router,
    render: (h) => h(App[lang]),
}).$mount("#app");
