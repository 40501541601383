<template>
  <div>
    <div class="banner flex justify-center align-center">
      <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/contact.jpg">
      <div class="title flex justify-center align-center">联系我们</div>
    </div>
    <div class="module-container">
      <div class="top-box flex justify-between flex-start wrap">
        <div class="left animated bounceInLeft">
          <div class="flex justify-between wrap">
            <div class="form-group">
              <p class="name">姓名</p>
              <input class="inp" type="text" maxlength="20" v-model="form.name">
            </div>
            <div class="form-group">
              <p class="name">邮箱</p>
              <input class="inp" type="text" maxlength="50" v-model="form.email">
            </div>
          </div>
          <div class="form-group full-width">
            <p class="name">意向</p>
            <input class="inp" type="text" maxlength="100" v-model="form.intention">
          </div>
          <div class="form-group full-width little-margin">
            <p class="name">附加消息</p>
            <textarea class="inp area" type="text" maxlength="200" v-model="form.message"></textarea>
          </div>
          <div class="form-group">
            <el-button class="sub-btn" @click="submit"><span style="color:#fff">立即提交</span></el-button>
          </div>
        </div>
        <div class="right animated bounceInRight">
          <p class="title jost">联系方式</p>
          <p class="desc muli">您可从下方获取到我们的联系方式，亦可通过右侧或底部的其他App与我们联系。</p>
          <div class="item muli ad">
            <p class="line title jost">地址</p>
            <div class="tab-box">
              <div class="tab-btn flex align-center">
                <div class="btn" :class="addressActiveBtn==1?'active':''" @click="addressActiveBtn=1">天津</div>
                <div class="btn" :class="addressActiveBtn==2?'active':''" @click="addressActiveBtn=2">香港</div>
                <div class="btn" :class="addressActiveBtn==3?'active':''" @click="addressActiveBtn=3">印度</div>
              </div>
              <div class="tab tab1" v-show="addressActiveBtn==1">
                <p class="line">怡景公寓9-2-204室，</p>
                <p class="line">中国天津市天津港保税区航空港经济区中央大道，邮编300300</p>
              </div>
              <div class="tab tab2" v-show="addressActiveBtn==2">
                <p class="line">15楼1508室，</p>
                <p class="line">中国香港九龙弥敦道625号大广场2号写字楼</p>
              </div>
              <div class="tab tab3" v-show="addressActiveBtn==3">
                <p class="line">Room 9-2-204, YiJing Flat,</p>
                <p class="line">Central Avenue, Air Port Economic Area, TianJin Port Free Trade Zone, TianJin 300300 China</p>
              </div>
            </div>
          </div>
          <div class="item muli em">
            <p class="line title">邮箱</p>
            <p class="line">sales@skyeychem.com </p>
          </div>
          <div class="item muli ph">
            <p class="line title">电话</p>
            <p class="line">+86 18722030668</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/assets/js/util';

const defaultForm={
  name: '',
  email: '',
  intention: '',
  message: ''
};
export default {
  data() {
    return {
      form: JSON.parse(JSON.stringify(defaultForm)),
      addressActiveBtn: 1
    }
  },

  methods: {
    submit: util.throttle(async function() {
      if(!this.form.name) return this.$message.error('请输入您的姓名');
      if(!this.form.email) return this.$message.error('请输入您的邮箱');
      if(!this.form.intention) return this.$message.error('请输入您的意向');
      if(!this.form.email.includes('@')) return this.$message.error('邮箱格式错误');

      this.$axios.post('intention',this.form).then(res => {
        const { data }=res;
        this.$message[data.code==1? 'success':'error'](data.msg.zh);
        if(data.code===1) this.form=JSON.parse(JSON.stringify(defaultForm));
      })
    })
  }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/contact.scss"></style>