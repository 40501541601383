<template>
    <div id="app" :class="($route.path === '/' ? 'home ' : '') + ($isMobile ? ' mobile' : '')" ref="app">
        <Header :options="headerOptions" :margin-top="headerMarginTop" v-if="!$isMobile"></Header>
        <mobile-header :options="headerOptions" v-else></mobile-header>
        <img class="leftImg" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/left.png" />
        <img class="rightImg" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/right.png" />
        <div class="page-container" :class="drawer ? 'large-padding' : ''">
            <router-view></router-view>
            <div class="subscribe" :class="$route.path == '/career' ? 'no-margin' : ''">
                <p class="title jost">Subscribe Now</p>
                <p><em class="desc jost">Timely obtain product update information and activity information</em></p>
                <div class="input-group flex">
                    <input class="inp" type="text" placeholder="Enter your email here" v-model="subscribe_email" />
                    <button class="btn" @click="subscribe">
                        <i class="iconfont">&#xe712;</i>
                    </button>
                </div>
                <div class="bottom-contact-box flex justify-center">
                    <div class="item flex justify-center align-center" @click="contact(1)">
                        <i class="iconfont" style="margin-top: 2px">&#xe6e5;</i>
                    </div>
                    <div class="item flex justify-center align-center" @click="contact(2)">
                        <i class="iconfont" style="font-size: 19px">&#xe882;</i>
                    </div>
                    <div class="item flex justify-center align-center" @click="contact(3)">
                        <i class="iconfont" style="font-size: 19px">&#xe62a;</i>
                    </div>
                    <div class="item flex justify-center align-center" @click="contact(4)">
                        <i class="iconfont" style="font-size: 15px; margin-bottom: 2px">&#xee48;</i>
                    </div>
                    <div class="item flex justify-center align-center" @click="contact(5)">
                        <i class="iconfont" style="font-size: 17px; margin-top: 2px">&#xe610;</i>
                    </div>
                </div>
                <div class="informations">
                    <!-- <p>Tel: +86 18722030668 <span>Email: sales@skyeychem.com</span></p> -->
                    <!-- <img class="left-img" src="@/assets/images/logo3.png" /> -->
                    <p>Copyright © 2022 TIANJIN SKYEY. All Rights Reserved.</p>
                    <p>
                        <a>Caustic Soda - General Information</a>
                        <a>Caustic Soda Flakes</a>
                        <a>Caustic Soda Pearls</a>
                        <a>About Us</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="right-contact-fixed-box" v-if="!$isMobile">
            <div class="item" @click="contact(1)"><img class="img" src="@/assets/images/facebook.jpg" /></div>
            <div class="item" @click="contact(2)"><img class="img" src="@/assets/images/skype.jpg" /></div>
            <div class="item" @click="contact(3)"><img class="img" src="@/assets/images/whatsapp.jpg" /></div>
            <div class="item" @click="contact(4)"><img class="img" src="@/assets/images/linkedin.jpg" /></div>
            <div class="item no-margin" @click="contact(5)"><img class="img" src="@/assets/images/wechat.jpg" /></div>
        </div>
        <el-drawer title="Allow local storage?" direction="btt" :size="`${$isMobile ? '290' : '200'}px`" :visible.sync="drawer" :modal="false" :wrapperClosable="false">
            <p>
                In order to provide you with a more personalized experience, this website may use the local storage capacity of
                your computer. We will not store your sensitive information or unreasonable data.
            </p>
            <p>
                Using local storage, such as cookies and localStorage, you can remember some of your settings on the website,
                such as the website language. The next time you open the website, you do not need to select the language again.
            </p>
            <div class="btn-box">
                <el-button class="bk" size="mini" @click="accept">Accept</el-button>
                <el-button size="mini" @click="decline">Decline</el-button>
            </div>
        </el-drawer>
        <div class="drawer-sizesit" :style="drawer ? `height:${$isMobile ? '290' : '200'}px` : ''"></div>
    </div>
</template>

<script>
import util from "@/assets/js/util"
import Header from "@/components/Header"
import MobileHeader from "@/components/MobileHeader"

const headerOptions = {
    publicPath: "",
    company: "SKYEY IMPEX LIMITED",
    motto: "Here can enter a short description of the company",
    home: "Home",
    product: "Products",
    about: "About",
    contact: "Contact",
    career: "Career"
}
export default {
    name: "App",
    components: { Header, MobileHeader },

    data() {
        return {
            headerOptions,
            subscribe_email: "",
            headerMarginTop: 0,
            drawer: localStorage.getItem("lang") === null ? true : false
        }
    },

    methods: {
        //本地存储使用 - 接受
        accept() {
            localStorage.setItem("lang", this.$store.state.lang)
            this.drawer = false
        },

        //本地存储使用 - 拒绝
        decline() {
            localStorage.setItem("lang", 0)
            this.drawer = false
        },

        //订阅
        subscribe: util.throttle(async function () {
            if (!this.subscribe_email) return this.$message.error("Please enter your email")
            if (!this.subscribe_email.includes("@")) return this.$message.error("Bad email format")
            this.$axios.post("/api/subscribe", { email: this.subscribe_email }).then(res => {
                const { data } = res
                this.subscribe_email = ""
                this.$message.success(data.msg.en)
            }).catch(err => {
                const { data } = err.response
                this.$message.error(data.msg.en)
            })
        }),

        //联系方式点击
        contact(type) {
            if (type == 1) window.location.href = "https://www.facebook.com/bryson.wang.73"
            if (type == 2) {
                if (navigator.clipboard && window.isSecureContext) {
                    navigator.clipboard.writeText("brysonwang").then(
                        () => {
                            this.$message.success("Skype user name copied to clipboard")
                        },
                        () => {
                            this.$message.error("Website need to be allowed to use a clipboard to copy content")
                        }
                    )
                } else this.$message.error("The browser version is too low. Please search Skype for user: brysonwang")
            }
            if (type == 3) window.location.href = "https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/whatsapp.jpg"
            if (type == 4) window.location.href = "https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/linkedin.jpg"
            if (type == 5) window.location.href = "https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/wechat.jpg"
        }
    },

    mounted() {
        if (!this.$isMobile) {
            this.$refs["app"].onscroll = e => {
                const scrollTop = e.srcElement.scrollTop
                if (scrollTop < 60) {
                    this.headerMarginTop = -1 * scrollTop
                } else {
                    this.headerMarginTop = -60
                }
            }
        }
    }
}
</script>

<style lang="scss" src="@/assets/style/page_style/layout.scss"></style>
