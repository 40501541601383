<template>
  <div>
    <div class="banner flex justify-center align-center">
      <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/career-banner-zh.jpg" />
    </div>

    <div class="module-container flex justify-between">
      <div class="left" v-if="!$isMobile">
        <div class="list-box">
          <div class="title">招聘类型</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 1, 0)">应届生</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 1, 1)">实习生</el-checkbox>
          </div>
        </div>
        <div class="list-box">
          <div class="title">招聘项目</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 2, 0)">2023秋招项目</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 2, 1)">社会招聘项目</el-checkbox>
          </div>
        </div>
        <div class="list-box">
          <div class="title">职位类别</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 3, 0)">研发</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 3, 1)">销售</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 3, 2)">运营</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 3, 3)">职能/支持</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 3, 4)">市场</el-checkbox>
          </div>
        </div>
        <div class="list-box">
          <div class="title">城市</div>
          <div class="item">
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 0)">天津</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 1)">北京</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 2)">上海</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 3)">广州</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 4)">深圳</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 5)">杭州</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 6)">成都</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 7)">香港</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 8)">新德里</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 9)">纽约</el-checkbox>
            <el-checkbox size="mini" class="ck" @change="fn($event, 4, 10)">伦敦</el-checkbox>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="no-content" v-show="!m1 && !m2">
          <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/no-content.png" />
          <p class="tip">该分类下暂无职位招聘，期待您保持关注...</p>
        </div>
        <div class="item" v-show="m1">
          <div class="title">人力资源实习生</div>
          <div class="desc flex align-center">
            <p class="bar">天津</p>
            <p class="bar">职能/支持 - 人力</p>
            <p class="bar">2023秋招项目</p>
            <p>实习</p>
          </div>
          <p class="paragraph">1、和业务部门沟通，快速、精准理解招聘需求；</p>
          <p class="paragraph">2、执行人才搜索和寻访计划，甄选、评估候选人匹配度并约面合适的候选人；</p>
          <p class="paragraph">3、参与其他人力资源相关工作（内部推荐、员工关系、培训、绩效、校园招聘等）；</p>
          <p class="paragraph">4、协助进行人力资源相关的数据统计工作，协助探索招聘的最佳实践。</p>
        </div>
        <div class="item" v-show="m2">
          <div class="title">HRBP - 人力资源伙伴</div>
          <div class="desc flex align-center">
            <p class="bar">天津</p>
            <p class="bar">职能/支持 - 人力</p>
            <p class="bar">社会招聘项目</p>
            <p>正式</p>
          </div>
          <p class="paragraph">1、负责业务部门的整体招聘进展；</p>
          <p class="paragraph">
            2、了解所支持部门的业务状况和团队运作状况，理解业务战略并促进人力资源战略在业务部门的有效实施；
          </p>
          <p class="paragraph">
            3、为团队的发展提供支持，应用专业理论、方法、工具，为业务团队提供全方位解决方案，
            包括招聘、培训、绩效、员工关系等人力资源工作；
          </p>
          <p class="paragraph">4、推动公司文化的建设和业务的高速发展。</p>
        </div>
        <div class="item" v-show="bs[3][1]">
          <div class="title">大客户销售 - 综合服务方向</div>
          <div class="desc flex align-center">
            <p class="bar">香港</p>
            <p class="bar">销售</p>
            <p class="bar">社会招聘项目</p>
            <p>正式</p>
          </div>
          <p class="paragraph">1、负责综合服务行业广告客户的开拓与维护工作，建立与企业的深度合作关系；</p>
          <p class="paragraph">2、协调内、外部的资源，确保广告及时投放、广告资源的合理利用和开发；</p>
          <p class="paragraph">3、与客户建立良好的合作关系，制定广告营销方案，协助解决客户的问题；</p>
          <p class="paragraph">4、完成团队及个人的销售任务，助力公司达成广告销售计划和目标。</p>
        </div>
        <div class="item" v-show="m2">
          <div class="title">Global Selling - 经营分析</div>
          <div class="desc flex align-center">
            <p class="bar">广州</p>
            <p class="bar">市场</p>
            <p class="bar">社会招聘项目</p>
            <p>正式</p>
          </div>
          <p class="paragraph">
            1、围绕业务增长飞轮搭建和完善经营分析框架，制定并迭代经营分析指标体系，深入分析业务核心驱动因素，清晰地定位出业务问题，为业务经营改善提供有力的策略建议；
          </p>
          <p class="paragraph">
            2、由上而下的建立目标与指标管理体系，参与年度/月度规划目标及策略制定，并针对业务执行策略效果监测、评估执行效果，推动业务目标达成；
          </p>
          <p class="paragraph">
            3、对于经营过程中出现的业务问题持续研究，推进专题分析，并推动较复杂项目在业务中的落地；
          </p>
          <p class="paragraph">
            4、制定业务未来模式探索的目标、策略，并协同业务进行策略的落地、监控、复盘以保证策略拿到预期结果。
          </p>
        </div>
        <div class="item" v-show="m2">
          <div class="title">数据挖掘</div>
          <div class="desc flex align-center">
            <p class="bar">北京</p>
            <p class="bar">市场</p>
            <p class="bar">社会招聘项目</p>
            <p>正式</p>
          </div>
          <p class="paragraph">
            1、基于对用户和产品的理解和海量数据，参与产品、运营、研发等多种场景的数据分析、实验设计和效果改进和数据建模等；
          </p>
          <p class="paragraph">
            2、研究数据挖掘或统计学习领域的前沿技术，针对海量用户行为和内容信息，挖掘更有价值的高阶数据；
          </p>
          <p class="paragraph">3、主动发起并推动忠于用户价值或产品体验的数据分析项目或算法策略落地。</p>
        </div>
        <div class="item" v-show="m2">
          <div class="title">营销经理</div>
          <div class="desc flex align-center">
            <p class="bar">北京</p>
            <p class="bar">市场</p>
            <p class="bar">社会招聘项目</p>
            <p>正式</p>
          </div>
          <p class="paragraph">1、完成本区域客户开发和维护：顾问式销售，为客户提供高品质的服务和有价值的建议；</p>
          <p class="paragraph">
            2、完成销售指标：负责当地中介客户谈判、签约，实现既定业绩收入，并完成公司交付的KPI，做好本区域的业务数据分析，监控业务运营情况，发掘可能存在的业绩增长点；
          </p>
          <p class="paragraph">3、老客户各个层面关系的维护，致力于专业服务，树立公司专业品牌形象；</p>
          <p class="paragraph">4、根据客户反馈提供信息帮助决策、改良产品并且不断提供超越客户预期的服务价值。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      m1: true,
      m2: true,
      bs: {
        1: [false, false],
        2: [false, false],
        3: Array(5).fill(false),
        4: Array(11).fill(false)
      }
    }
  },

  methods: {
    fn(b, n, i) {
      this.bs[n][i] = b
      this.deal()
    },

    deal() {
      let bs = this.bs,
        m1 = true,
        m2 = true
      for (let i = 1; i <= 4; i++) {
        let is = false
        for (let j = 0; j < bs[i].length; j++) {
          if (bs[i][j]) is = true
        }
        if (is && i == 1) {
          if (bs[1][1]) m2 = false
          else return (this.m1 = false), (this.m2 = false)
        }
        if (is && i == 2) {
          if (!bs[2][0]) m1 = false
          if (!bs[2][1]) m2 = false
        }
        if (is && i == 3 && !bs[3][3]) return (this.m1 = false), (this.m2 = false)
        if (is && i == 4 && !bs[4][0]) return (this.m1 = false), (this.m2 = false)
        if (!m1 && !m2) break
      }
      this.m1 = m1
      this.m2 = m2
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/career.scss"></style>
