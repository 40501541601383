<template>
  <div>
    <div class="banner flex justify-center align-center">
      <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/about.jpg">
      <div class="title flex justify-center align-center">关于我们</div>
    </div>
    <div class="module-container">
      <div class="top-desc-box flex justify-between align-start wrap animated slideInRight faster">
        <div class="left flex justify-center align-center">
          <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/office.jpg">
        </div>
        <div class="right zh">
          <p class="title">天津天域进出口有限公司</p>
          <p class="paragraph">
            JavaScript最初由Netscape的Brendan Eich设计，最初将其脚本语言命名为LiveScript，后来Netscape在与Sun合作之后将其改名为JavaScript。JavaScript最初受Java启发而开始设计的，目的之一就是“看上去像Java”，因此语法上有类似之处，一些名称和命名规范也借自Java，但JavaScript的主要设计原则源自Self和Scheme。JavaScript与Java名称上的近似，是当时Netscape为了营销考虑与Sun微系统达成协议的结果。微软同时期也推出了JScript来迎战JavaScript的脚本语言。
          </p>
          <p class="paragraph">
            发展初期，JavaScript的标准并未确定，同期有Netscape的JavaScript，微软的JScript和CEnvi的ScriptEase三足鼎立。为了互用性，Ecma国际（前身为欧洲计算机制造商协会）创建了ECMA-262标准（ECMAScript），两者都属于ECMAScript的实现，尽管JavaScript作为给非程序人员的脚本语言，而非作为给程序人员的脚本语言来推广和宣传，但是JavaScript具有非常丰富的特性。1997年，在ECMA（欧洲计算机制造商协会）的协调下，由Netscape、Sun、微软、Borland组成的工作组确定统一标准：ECMA-262。完整的JavaScript实现包含三个部分：ECMAScript，文档对象模型，浏览器对象模型。
          </p>
        </div>
      </div>

      <div class="qa-box">
        <div class="content-title">常见问题</div>
        <div class="item">
          <div class="title">What's JavaScript? How can i use it?</div>
          <div class="paragraph">JavaScript (abbreviated as "JS") is a lightweight, interpreted or just in time compiled programming language with function priority. Although it is famous as a scripting language for developing Web pages, it is also used in many non browser environments. JavaScript
            is
            based on prototype programming, multi paradigm dynamic scripting language, and supports object-oriented, imperative, declarative, and functional programming paradigms.</div>
          <div class="paragraph">JavaScript was first designed and implemented on Netscape Navigator browser by Brendan Eich of Netscape in 1995. Because Netscape works with Sun, the Netscape management wants it to look like Java, so it is named JavaScript. But in fact, its syntax style is closer to
            Self and Scheme.</div>
        </div>
        <div class="item">
          <div class="title">What's JavaScript? How can i use it?</div>
          <div class="paragraph">JavaScript (abbreviated as "JS") is a lightweight, interpreted or just in time compiled programming language with function priority. Although it is famous as a scripting language for developing Web pages, it is also used in many non browser environments. JavaScript
            is
            based on prototype programming, multi paradigm dynamic scripting language, and supports object-oriented, imperative, declarative, and functional programming paradigms.</div>
          <div class="paragraph">JavaScript was first designed and implemented on Netscape Navigator browser by Brendan Eich of Netscape in 1995. Because Netscape works with Sun, the Netscape management wants it to look like Java, so it is named JavaScript. But in fact, its syntax style is closer to
            Self and Scheme.</div>
        </div>
        <div class="item">
          <div class="title">What's JavaScript? How can i use it?</div>
          <div class="paragraph">JavaScript (abbreviated as "JS") is a lightweight, interpreted or just in time compiled programming language with function priority. Although it is famous as a scripting language for developing Web pages, it is also used in many non browser environments. JavaScript
            is
            based on prototype programming, multi paradigm dynamic scripting language, and supports object-oriented, imperative, declarative, and functional programming paradigms.</div>
          <div class="paragraph">JavaScript was first designed and implemented on Netscape Navigator browser by Brendan Eich of Netscape in 1995. Because Netscape works with Sun, the Netscape management wants it to look like Java, so it is named JavaScript. But in fact, its syntax style is closer to
            Self and Scheme.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  mounted() {
    $(".qa-box").removeClass("show animated faster slideInLeft");
    const qaHeight=$(".qa-box").offset().top,
      halfWindowHeight=window.innerHeight/1.5;

    $("#app").scroll(() => {
      if($("#app").scrollTop()>=qaHeight-halfWindowHeight) $(".qa-box").addClass('show animated faster slideInLeft');
    })
  },

  destroyed() {
    $("#app").unbind('scroll')
  }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/about.scss"></style>