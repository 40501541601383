<template>
  <div class="module-container">
    <swiper ref="swiper" :options="swiperOptions">
      <swiper-slide class="flex justify-center align-center">
        <router-link to="products">
          <img
            class="img"
            src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/products2.png?x-oss-process=style/suolue"
          />
        </router-link>
      </swiper-slide>
      <swiper-slide class="flex justify-center align-center">
        <router-link to="contact">
          <img
            class="img"
            src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/contact.jpg?x-oss-process=style/suolue"
          />
        </router-link>
        <p class="txt">联系我们</p>
      </swiper-slide>
      <swiper-slide class="flex justify-center align-center">
        <router-link to="about">
          <img
            class="img"
            src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/about.jpg?x-oss-process=style/suolue"
          />
        </router-link>
        <p class="txt">关于我们</p>
      </swiper-slide>
      <swiper-slide class="flex justify-center align-center">
        <router-link to="career">
          <img
            class="img"
            src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/career-banner.png?x-oss-process=style/suolue"
          />
        </router-link>
        <p class="txt">招聘</p>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <div class="advantage animated slideInRight faster">
      <div class="content-title">我们的优势</div>
      <div class="list-box flex justify-between wrap">
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont" style="font-size: 62px">&#xeb36;</i>
          </div>
          <div class="bottom">
            <p class="txt">卓越的服务</p>
            <p class="txt">迅速反应，马上行动，在短的时间，用有效的方法，为用户提供满意的服务</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont" style="font-size: 48px">&#xe7ed;</i>
          </div>
          <div class="bottom">
            <p class="txt">强大的物流网络</p>
            <p class="txt">便利的物流通道，靠近丰富的原料产地，位于经济发展重点区域</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont">&#xe638;</i>
          </div>
          <div class="bottom">
            <p class="txt">质量保证</p>
            <p class="txt">公司主业集中，近三年来收入增速高于行业平均，设备优良、工艺先进、质量可靠</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont">&#xe7d4;</i>
          </div>
          <div class="bottom">
            <p class="txt">稳定的供应链</p>
            <p class="txt">具备精准的采买能力及需据市场的需求，进行产品的研发</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont">&#xe609;</i>
          </div>
          <div class="bottom">
            <p class="txt">多地办事处</p>
            <p class="txt">公司在多个国家及城市设有分公司与办事处，与客户近距离沟通</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont">&#xe62d;</i>
          </div>
          <div class="bottom">
            <p class="txt">强大的研发能力</p>
            <p class="txt">不断研发新的产品，采用最新制造工艺，推出多款系列产品</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont" style="font-size: 42px">&#xe6a4;</i>
          </div>
          <div class="bottom">
            <p class="txt">品牌力量</p>
            <p class="txt">取得的产品价格优势及产口规模优势获上万消费者的认可</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont" style="font-size: 34px">&#xe61a;</i>
          </div>
          <div class="bottom">
            <p class="txt">售后服务</p>
            <p class="txt">专业售后服务团队和良好的售后服务信誉，提供全程、不间断售后服务</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont" style="font-size: 42px">&#xe66f;</i>
          </div>
          <div class="bottom">
            <p class="txt">庞大人才队伍</p>
            <p class="txt">专业的销售团队、客服团队、售后团队与研发团队等</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont">&#xe613;</i>
          </div>
          <div class="bottom">
            <p class="txt">先进的生产线</p>
            <p class="txt">使用全自动化生产线大批量生产的产品，有效提高生产效率和节省人力成本</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont" style="font-size: 42px">&#xe614;</i>
          </div>
          <div class="bottom">
            <p class="txt">掌握核心技术</p>
            <p class="txt">研发体系不断革新，吸引研发人才，助力产业发展</p>
          </div>
        </div>
        <div class="item">
          <div class="top flex justify-center align-center">
            <i class="iconfont">&#xe71a;</i>
          </div>
          <div class="bottom">
            <p class="txt">优质的产品</p>
            <p class="txt">产品来源渠道多，距离短，成本低</p>
          </div>
        </div>
      </div>
    </div>

    <div class="home-module hot-products animated faster slideInLeft">
      <div class="content-title large-margin">热门产品</div>
      <div class="list-box flex justify-between wrap">
        <div class="item" v-for="item in 10" :key="item">
          <div class="overlay flex justify-center align-center">
            <div class="icon flex justify-center align-center">
              <i class="iconfont">&#xe712;</i>
            </div>
          </div>
          <img
            :src="`https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/products/product${
              item > 8 ? (item % 8) + 1 : item
            }.png`"
          />
        </div>
      </div>
    </div>

    <div class="home-module about-us">
      <div class="content-title large-margin">
        <p>关于我们</p>
      </div>
      <div class="flex justify-bewteen">
        <div class="img-box">
          <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/skyey-photo1.png" />
        </div>
        <div class="right">
          <p class="title">Welcome to the most reliable and professional polymer supplier.</p>
          <p class="paragraph">
            Tianjin Skyey Chemical Co., Ltd, is more than 10 years professional exporter of Caustic Soda (Sodium
            Hydroxide) mainly, and also including PVC, Potassium Hydroxide, SLES, Sodium Bicarbonate, Citric Acid,
            Acetic Acid, Sodium Sulphide, Sodium Hydrosulphite, TCCA, Phosphoric Acid, Soda Ash, Sodium Sulphate
            Anhydrous, etc. We are also glad to help or assist customers to well purchase in China. Managing philosophy
            of “Integrity can lead to long-term”, not only sourcing in China but globally procurement, guaranteed
            product quality with competitive price, excellent service provided by our professional team, these all above
            gained us trustworthy.
          </p>
          <p class="paragraph">
            Tianjin Skyey Chemical Co., Ltd, is more than 10 years professional exporter of Caustic Soda (Sodium
            Hydroxide) mainly, and also including PVC, Potassium Hydroxide, SLES, Sodium Bicarbonate, Citric Acid,
            Acetic Acid, Sodium Sulphide, Sodium Hydrosulphite, TCCA, Phosphoric Acid, Soda Ash, Sodium Sulphate
            Anhydrous, etc.
          </p>
          <router-link to="about">
            <button class="read-more-btn">阅读详情&gt;&gt;</button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="home-module marketing-network">
      <div class="content-title large-margin">
        <p>销售网络</p>
      </div>
      <div class="container flex justify-between">
        <div class="left">
          <p class="motto">MARKETING</p>
          <p class="motto with-bar">NETWORK</p>
          <p class="paragraph">
            Europe, Africa and other more than and 100 countries, by domestic and foreign customers the review.
          </p>
          <button class="bottom-btn flex justify-between align-center">
            <i class="iconfont">&#xe60c;</i>
            <a href="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/whatsapp.jpg"><p>立即联系</p></a>
          </button>
        </div>
        <div class="right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper"
import "swiper/css/swiper.css"
import $ from "jquery"

export default {
  name: "App",
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive
  },

  data() {
    return {
      drawer: localStorage.getItem("lang") === null ? true : false,
      swiperOptions: {
        loop: true,
        autoplay: 10000,
        paginationClickable: true,
        slidesPerView: this.$isMobile ? 1 : 2,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    }
  },

  mounted() {
    $(".home-module").removeClass("show animated faster slideInLeft slideInRight")
    const moduleHeights = [],
      halfWindowHeight = window.innerHeight / 1.2
    $(".home-module").each((_, elem) => {
      moduleHeights.push($(elem).offset().top)
    })
    $("#app").scroll(() => {
      for (let i = 0; i < moduleHeights.length; i++) {
        if ($("#app").scrollTop() >= moduleHeights[i] - halfWindowHeight) {
          $($(".home-module")[i]).addClass(`show animated faster slideIn${i % 2 === 0 ? "Left" : "Right"}`)
        }
      }
    })
  },

  destroyed() {
    $("#app").unbind("scroll")
  }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/home.scss"></style>
