import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

const oriLang = localStorage.getItem("lang");
export default new Vuex.Store({
    state: {
        lang: !oriLang || oriLang == "0" ? "en" : oriLang,
    },
});
