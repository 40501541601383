<template>
    <div>
        <div class="banner flex justify-center align-center">
            <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/about.jpg">
            <div class="title flex justify-center align-center">About Us</div>
        </div>
        <div class="module-container">
            <div class="qa-box animated faster slideInRight">
                <div class="item">
                    <div class="top-desc-box flex justify-between align-start wrap animated slideInRight faster">
                        <p class="title">Company</p>
                        <p class="paragraph">
                            SKYEY GROUP is an International Trading Company headquartered in Tianjin, China. We have affiliate offices in Hong Kong, India and United Sates. We have many years of experience in sourcing, trading, exporting and shipping Caustic Soda (Sodium Hydroxide) and other basic chemicals to International Clients. We deal in a variety of chemicals for different applications such as, Detergent, Water treatment, Mining, Oil drilling, Fertilizer and Plastics etc.
                        </p>
                        <p class="paragraph">
                            At SKYEY, we are dedicated to support our customers by providing them the right products at the right time with competitive price. Our clients consider us as their most efficient and reliable link in their value chain.
                        </p>
                        <p class="paragraph">
                            SKYEY has been dealing with chemicals since 2010. Our main product is Caustic Soda and we are one of the leading exporters of Caustic Soda from China. We source Caustic Soda globally, namely, from China, India, Bangladesh and Middle East. So far, we have exported caustic soda to more than 102 ports in 49 countries.
                        </p>
                        <p class="paragraph">
                            Through our global supply chain, we ensure to provide best quality product at competitive prices with on time delivery.
                        </p>
                        <p class="paragraph">
                            Our strong relationships with various shipping lines enables us guaranteed container bookings at favorable rates, and thereby, ensures our customers with both punctuality and competitive product pricing.
                        </p>
                        <p class="paragraph">
                            At SKYEY, our experienced and talented staff always ensures that our customers get the best possible service, best quality product and timely delivery.
                        </p>
                        <div class="img-box">
                            <img class="img" src="https://tianjin-skyey.oss-ap-southeast-1.aliyuncs.com/assets/marketing-network2.png">
                        </div>
                    </div>
                </div>
                <div class="item">
                    <p class="title">Our Mission</p>
                    <p class="paragraph">
                        To provide customers quality and affordable raw materials and become the trustworthy partner in the specified field.
                    </p>
                </div>
                <div class="item">
                    <p class="title">Our Vision</p>
                    <p class="paragraph">
                        To develop sustainable international business cooperation and make enduring value in the specified field.
                    </p>
                </div>
                <div class="item">
                    <p class="title">Our Values</p>
                    <p class="paragraph">
                        <b>Responsibility:</b> We undertake responsibilities for our customers and suppliers.
                    </p>
                    <p class="paragraph">
                        <b>Integrity:</b> We value integrity the foremost and devlop open and honest relationship based on trust.
                    </p>
                    <p class="paragraph">
                        <b>Entrepreneurship:</b> We stay entrepreneurial and keep continuous improvement.
                    </p>
                    <p class="paragraph">
                        <b>Hard working:</b> We pursue our goals relentlessly.
                    </p>
                    <p class="paragraph">
                        <b>Quality:</b> We regard the quanlity as our life.
                    </p>
                    <p class="paragraph">
                        <b>Service:</b> We think in our customers' position and respond promptly and attentively.
                    </p>
                    <p class="paragraph">
                        <b>Win-Win:</b> We trust only win-win can cooperate for a long time.
                    </p>
                </div>
                <div class="item">
                    <p class="title">Contact</p>
                    <p class="paragraph">
                        SKYEY are your eyes in the chemical industry in China and also in the international market. We avail the latest market information we gather to strengthen our relationship with you. With offices in China and abroad, we are able to provide you the best information and advice about when to buy and sell. We are also capable to find the product you need which is beyond the product list shown in this website, with good quality and right price.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    mounted() {
        $(".qa-box .item").removeClass("show animated faster slideInLeft slideInRight");
        const qaHeights = [], halfWindowHeight = window.innerHeight / 1.2;
        $(".qa-box .item").slice(1).each((_, elem) => {
            qaHeights.push($(elem).offset().top)
        })
        $("#app").scroll(() => {
            for (let i = 0; i < qaHeights.length; i++) {
                if ($("#app").scrollTop() >= qaHeights[i] - halfWindowHeight) {
                    $($(".qa-box .item")[i + 1]).addClass(`show animated faster slideIn${i % 2 === 0 ? "Left" : "Right"}`);
                }
            }
        })
    },

    destroyed() {
        $("#app").unbind('scroll')
    }
}
</script>

<style lang="scss" scoped src="@/assets/style/page_style/about.scss"></style>